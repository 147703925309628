import { useRef, useState } from 'react';
import './array-group.scss';
import { FaTrash, FaPlusCircle } from 'react-icons/fa';


function ArrayGroup({ items = [], handleModification = () => {}, validators = [] }) {
    const newItemRef = useRef();
    const addBtnRef = useRef();
    const [addButtonEnabled, setAddButtonEnabled] = useState(false);

    const isInputValid = (value) => {
        return validators.length === 0 || validators.every(validator => validator(value));
    }

    const handleRemoveItemClick = (ev, index) => {
        ev.preventDefault();
        handleModification({
            type: 'remove',
            index,
        });
    };

    const handleTextModification = (ev, index) => {
        ev.preventDefault();
        const value = ev.nativeEvent.target.value;
        if (validators.length === 0 || validators.every(validator => validator(value))) {
            handleModification({
                type: 'textChange',
                index,
                text: value,
            });
        }
    }

    const handleAddItemClick = (ev) => {
        ev.preventDefault();
        const value = newItemRef.current.value;
        if (isInputValid(value)) {
            handleModification({
                type: 'new',
                text: newItemRef.current.value,
            });
            newItemRef.current.value = '';
            newItemRef.current?.focus?.();
        }
    }

    const handleInput = (ev) => {
        setAddButtonEnabled(isInputValid(ev.nativeEvent.target.value));
    }

    const handleKeyDown = (ev) => {
        if (ev.nativeEvent.key === 'Enter') {
            ev.preventDefault();
            addBtnRef.current?.click();
        }

        if (ev.nativeEvent.key === 'Escape') {
            ev.nativeEvent.target.value = '';
        }
    }

    const getItemsHTML = () => {
        return items.map((item, index) => (
            <div className="items-row" key={index}>
                <input
                    className='input-text double'
                    onChange={(ev) => handleTextModification(ev, index)}
                    type="text"
                    value={item} />
                <button
                    className="btn btn--danger"
                    onClick={(ev) => handleRemoveItemClick(ev, index)}>
                        <FaTrash />
                    </button>
            </div>
        ));
    }

    return (
        <div className="array-group">
            {getItemsHTML()}
            <div className="items-row">
            <input
                    className='input-text single'
                    type="text"
                    ref={newItemRef}
                    onKeyDown={handleKeyDown}
                    onInput={handleInput}
                    placeholder="Nuevo.." />
                <button
                    className="btn" 
                    ref={addBtnRef}
                    disabled={!addButtonEnabled}
                    onClick={(ev) => handleAddItemClick(ev)}>
                        <FaPlusCircle />
                    </button>
            </div>
        </div>
    );
};

export default ArrayGroup;
