import './Card.scss';
import { Link } from 'react-router-dom';

function Card(props) {
    return (
        <Link to={`/receta/${props.recipe_id}`} className="card-link">
            <div className="card">
                <div className="card-header" title={props.title}>
                    <img className="card-header-image" src={props.image || '/files/default_recipe.jpg'} alt={props.title} />
                    <h1 className="card-header-title">{props.title}</h1>
                </div>

                <div className="card-body">
                    <p>{props.ingredients.join(', ')}</p>
                </div>

            </div>
        </Link>
    );
};

export default Card;
