import { useEffect } from "react"

const updater = (newState) => {
    document.title = newState;
}

function useTitle(initialState) {
    useEffect(() => {
        document.title = initialState;
    }, [initialState]);  
    return [initialState, updater];
}

export default useTitle;