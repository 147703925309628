import { Link } from 'react-router-dom';
import { FaPen, FaTrash } from 'react-icons/fa';
import './Recipe.scss';
import { useContext } from 'react';
import { ToastContext } from '../../providers/Toast.provider';

function Recipe({ recipe }) {
    const { createToast } = useContext(ToastContext);


    const handleDelete = () => {
        createToast({ type: 'error', text: 'Por el momento no se pueden eliminar recetas.' });
    }

    return (
        <div className="recipe">
            <div className="recipe-header">
                <img className="recipe-header-image" src={recipe.image || '/files/default_recipe.jpg'} alt={recipe.title} />
                <h1 className="recipe-header-title">{recipe.title}</h1>
                <div className="recipe-options">
                    <Link to={`/receta/${recipe.recipe_id}/edit`}
                        className="recipe-option">
                        <FaPen />
                    </Link>
                    <button className="recipe-option" onClick={handleDelete}>
                        <FaTrash />
                    </button>
                </div>
            </div>
            <div className="recipe-body">
                <div className="recipe-body-block">
                    <p className="recipe-body-description">{recipe.description}</p>
                </div>

                <div className="recipe-body-block">
                    <h2>Ingredientes:</h2>
                    <ul>
                        {recipe.ingredients.map((ingredient, index) => (
                            <li className="recipe-body-list-item" key={index}>
                                <input id={`ingredient-${index}`} type="checkbox"></input>
                                <label htmlFor={`ingredient-${index}`}>{ingredient}</label>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="recipe-body-block">
                    <h2>Instrucciones:</h2>
                    <ol>
                        {recipe.instructions.map((instruction, index) => (<li className="recipe-body-list-item" key={index}>{index + 1}. {instruction}</li>))}
                    </ol>
                </div>

                <div className="recipe-body-block">
                    <h2>Etiquetas:</h2>
                    <div className="tag-list">
                        {recipe.tags.map((tag, index) => (<div className="tag" key={index}>{tag}</div>))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Recipe;