import { useContext, useState } from "react";
import './recipe-editor.scss';
import ArrayGroup from "../../../../components/array-group/array-group";
import { updateRecipe } from '../../services/update-recipe.service';
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../../../../providers/Toast.provider";

export function RecipeEditor({ recipe }) {
    const [editedRecipe, setEditedRecipe] = useState({ ...recipe });
    const [edited, setEdited] = useState(false);
    const navigate = useNavigate();
    const { createToast } = useContext(ToastContext);

    function textValidator(value) {
        return value.trim().length > 0;
    }

    function handleChange(key, value) {
        setEdited(true);
        setEditedRecipe({ ...editedRecipe, [key]: value });
    }

    function handleImageChange(ev) {
        setEdited(true);
        const fileReader = new FileReader();
        const file = ev.target.files[0];
        fileReader.addEventListener('load', () => {
            setEditedRecipe({ ...editedRecipe, image: {
                data: fileReader.result,
                file: file,
            } });
        });
        fileReader.readAsDataURL(file);
    }

    function handleEditIn (key, modification) {
        setEdited(true);
        let newValue = [...editedRecipe[key]];

        switch (modification.type) {
            case 'new':
                if (modification.text.trim().length) {
                    newValue = [...newValue, modification.text];
                }
                break;
            case 'textChange':
                if (modification.text.trim().length) {
                    newValue[modification.index] = modification.text;
                }
                break;
            case 'remove':
                newValue = [...newValue.filter((_, ind) => ind !== modification.index)];
                break;
            default:
        }
        setEditedRecipe({ ...editedRecipe, [key]: newValue });
    }

    function handleSave(ev) {
        ev.preventDefault();

        const formData = new FormData();
        formData.append('title', editedRecipe.title);
        formData.append('description', editedRecipe.description);
        formData.append('ingredients', JSON.stringify(editedRecipe.ingredients));
        formData.append('instructions', JSON.stringify(editedRecipe.instructions));
        formData.append('tags', JSON.stringify(editedRecipe.tags));
        if (typeof(editedRecipe.image) === 'object' && editedRecipe.image.hasOwnProperty('file')) {
            formData.append('image', editedRecipe.image.file);
        }

        updateRecipe(editedRecipe.recipe_id, formData)
            .then(response => {
                if (response.message === 'Updated') {
                    createToast({ type: 'success', text: 'Receta actualizada, redireccionando...', ttl: 1500 });
                    setEdited(false);
                    setTimeout(() => {
                        navigate(-1, { replace: true });
                    }, 1500);
                } else {
                    createToast({ type: 'error', text: 'No se pudo procesar la respuesta del servidor.', ttl: 3000 });
                }
            })
            .catch(err => {
                createToast({ type: 'error', text: 'Ups! Ocurrió un error y no pudimos actualizar los datos.', ttl: 3000 });
                console.error(err);
            });
    }

    return <form className="edit-recipe-form">
        <div className="input-group">
            <label htmlFor="title">Título</label>
            <input
                id="title"
                className="input-text"
                type="text"
                value={editedRecipe.title}
                onChange={(ev) => handleChange('title', ev.nativeEvent.target.value)}
            />
        </div>

        <div className="input-group">
            <label htmlFor="description">Descripción</label>
            <input
                id="description"
                className="input-text"
                type="text"
                value={editedRecipe.description}
                onChange={(ev) => handleChange('description', ev.nativeEvent.target.value)}
            />
        </div>

        <div className="input-group">
            <label htmlFor="imagen">Imagen</label>
            <div className="input-group-image">
                <img className="input-image" src={editedRecipe.image?.data || editedRecipe.image} alt="Recipe" />
                <input
                    id="imagen"
                    className="input-text"
                    type="file"
                    accept="image/jpg,image/png,image/jpeg"
                    onChange={(ev) => handleImageChange(ev.nativeEvent)}
                />
            </div>
        </div>

        <div className="input-group">
            <label>Ingredientes</label>
            <ArrayGroup
                items={editedRecipe.ingredients}
                handleModification={(ev) => handleEditIn('ingredients', ev)}
                validators={[textValidator]} />
        </div>

        <div className="input-group">
            <label>Instrucciones</label>
            <ArrayGroup
                items={editedRecipe.instructions}
                handleModification={(ev) => handleEditIn('instructions', ev)}
                validators={[textValidator]} />
        </div>

        <div className="input-group">
            <label>Etiquetas</label>
            <ArrayGroup
                items={editedRecipe.tags}
                handleModification={(ev) => handleEditIn('tags', ev)}
                validators={[textValidator]} />
        </div>

        <div className="input-group">
            <button className="btn btn--valid" onClick={handleSave} disabled={!edited}>Guardar</button>
        </div>

    </form>
}