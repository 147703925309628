import Vault from '../../helpers/Vault';
import { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { check } from '../../helpers/Request';
import './styles.scss';
const vault = Vault.getInstance();

export function Unlock() {
    const pwdRef = useRef();
    const btnRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    const kdHandler = (ev) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();
            btnRef.current.click();
        }
    }
    
    const unlockHandler = (ev) => {
        ev.preventDefault();
        const pwd = pwdRef.current.value;

        fetch('/api/vault/unlock', {
            method: 'POST',
            body: JSON.stringify({ password: pwd }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                }
                alert('Contraseña incorrecta.');
            })
            .then(data => {
                if (data) {
                    vault.setItem('session', data.session);
                    localStorage.setItem('session', data.session);
                    const search = new URLSearchParams(location.search?.slice(1));
                    const r = search.get('r');
                    if (r) {
                        return navigate(atob(r));
                    }
                    navigate('/');
                }
            })
            .catch(_ => {
                console.log(_);
                alert('Ha ocurrido un error al intentar desbloquear la aplicación.');
            });
    };

    useEffect(() => {
        check()
            .then(value => {
                if (value) {
                    const search = new URLSearchParams(location.search?.slice(1));
                    const r = search.get('r');
                    if (r) {
                        navigate(atob(r), { replace: true });
                        return null;
                    }
                    navigate('/', { replace: true });
                }
            });
    //eslint-disable-next-line
    }, []);

    return (
        <div className="unlock-container">
            <h1 className="application-name">Nuestras Recetas</h1>
            <label className="unlock-label" htmlFor="unlock-pwd">Ingrese contraseña</label>
            <input id="unlock-pwd" ref={pwdRef} className="input-text" type="password" onKeyDown={kdHandler} placeholder="Contraseña"></input>
            <button ref={btnRef} className="btn" onClick={unlockHandler}>Ingresar</button>
        </div>
    );
}
